.OurServe {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.OurServe .OurServe-Top h1 {
  opacity: 1;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #1d2129;
  text-align: center;
}

.OurServe .OurServe-Top p {
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #1d2129;
  text-align: center;
}

.OurServe .OurServe-Img img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 32px;
}

.OurServe .OurServe-Bottom-Row {
  padding: 60px 0;
}

.OurServe .OurServe-Bottom-Row .OurServe-Bottom-Box h3 {
  opacity: 1;
  font-size: 40px;
  font-weight: 700;
  color: #1d2129;
  text-align: left;
  vertical-align: top;
}

.OurServe .OurServe-Bottom-Row .OurServe-Bottom-Box p {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: #1d2129;
  text-align: left;
  vertical-align: top;
}

.OurProducts {
  padding: 80px 80px 80px 80px;
  background-color: #f9fafb;
}

.OurProducts .OurProducts-top h1 {
  opacity: 1;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #1d2129;
}

.OurProducts .OurProducts-top .OurProducts-p {
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #1d2129;
}

.OurProducts .OurProducts-Bottom img {
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 32px !important;
}

.OurProducts .OurProducts-Bottom .OurProducts-Bottom-Col {
  padding-left: 40px;
}

.OurProducts .OurProducts-Bottom .OurProducts-Bottom-Col .OurProducts-Tag {
  margin-top: 24px;
}

.OurProducts .OurProducts-Bottom .OurProducts-Bottom-Col .OurProducts-Tag h5 {
  opacity: 1;
  font-size: 24px;
  font-weight: 700;
}

.OurProducts .OurProducts-Bottom .OurProducts-Bottom-Col .OurProducts-Tag p {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: #86909c;
}

.CoreFunctions {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.CoreFunctions .CoreFunctions-Col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoreFunctions .CoreFunctions-Col .CoreFunctions-Img {
  width: 1440px;
  height: 620px;
  display: flex;
}

.CoreFunctions .CoreFunctions-Col .CoreFunctions-Img img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 32px !important;
}

.CoreFunctions .CoreFunctions-Bottom {
  padding: 0 60px;
}

.CoreFunctions .CoreFunctions-Bottom .CoreFunctionsFirstCol h4 {
  opacity: 1;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #1d2129;
}

.CoreFunctions .CoreFunctions-Bottom .CoreFunctionsFirstCol p {
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: #86909c;
}

.PricePlan {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.PricePlan .PricePlan-Card {
  text-align: center;
  width: 100%;
  border-radius: 24px;
}

.PricePlan .PricePlan-Card h6 {
  font-size: 20px;
  font-weight: 700;
  color: #86909c;
}

.PricePlan .PricePlan-Card h1 {
  font-size: 56px;
  font-weight: 700;
  color: #1d2129;
}

.PricePlan .PricePlan-Card span {
  font-size: 16px;
  font-weight: 700;
  color: blue;
}

.PricePlan .PricePlanFunction {
  padding-left: 30px;
}

.PricePlan .PricePlanFunction i {
  padding-right: 5px;
}

.PricePlan .PricePlanButton {
  padding-top: 64px;
  text-align: center;
}

.PricePlan .PricePlanButton button:hover {
  background-color: #40a9ff;
  color: #ffffff;
}
