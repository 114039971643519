.InterfaceDesign {
  align-items: center;
  padding: 80px 80px 80px 80px;
  background: #f9fafb;
}

.InterfaceDesign .Interface-Img {
  overflow: hidden;
}

.InterfaceDesign .Interface-Img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.InterfaceDesign .Interface-Text h2 {
  opacity: 1;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 56px;
  color: #1d2129;
  text-align: left;
  vertical-align: top;
}

.InterfaceDesign .Interface-Text p {
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28px;
  color: #1d2129;
}

@media (min-width: 768px) {
  .Interface-Text {
    padding-left: 50px;
  }
}

@media (max-width: 576px) {
  .Interface-Text {
    margin-top: 30px !important;
  }
}

.DataAnalysis {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.DataAnalysis .DataAnalysis-Left h2 {
  opacity: 1;
  font-size: 48px;
  font-weight: 700;
  color: #1d2129;
}

.DataAnalysis .DataAnalysis-Left p {
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
  color: #1d2129;
}

.DataAnalysis .DataAnalysis-Right-Text h3 {
  opacity: 1;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #1787fc;
}

.DataAnalysis .DataAnalysis-Right-Text p {
  opacity: 1;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #1d2129;
}

@media (min-width: 768px) {
  .ant-row .DataAnalysis-Left {
    padding-right: 80px;
  }
  .ant-row .DataAnalysis-Left .DataAnalysis-Left-text {
    text-align: center;
  }
}

.Information {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.Information .Information-Card {
  border-radius: 28px;
}

.Information .Information-Card img {
  max-width: 100%;
  height: auto;
  border-radius: 28px;
  height: auto;
  object-fit: cover;
}

.Information-Title h1 {
  font-size: 24px;
  font-weight: 800;
}

.Information-Title p {
  font-size: 24px;
  font-weight: 400;
}
