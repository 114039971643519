.NavBar {
  padding: 0 60px 0 60px;
  height: 84px;
  background-color: white;
}

.NavBar p {
  margin-top: 15px;
  font-size: 28px;
  font-weight: 700;
}

.NavBar .NavBar-Menu {
  width: 100%;
}

.NavBar .NavBar-Menu .ant-menu-title-content {
  font-size: 15px;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .NavBar-Button {
    width: 100%;
    display: block;
  }
  .NavBar-Menu {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .NavBar-Button {
    display: none;
  }
}
