.CustomerStories {
  padding: 80px 80px 80px 80px;
  background-color: #f9fafb;
}

.CustomerStories .CustomerStories-Left .CustomerStories-Left-text h1 {
  font-size: 56px;
  font-weight: 700;
  letter-spacing: 0px;
}

.CustomerStories .CustomerStories-Left .CustomerStories-Left-text p {
  font-size: 18px;
  font-weight: 400;
}

.CustomerStories .CustomerStories-Left .CustomerStories-Left-text button + button {
  margin-bottom: 5px;
}

.CustomerStories .CustomerStories-Left .CustomerStories-Left-text :not(:first-child) {
  margin-left: 5px;
}

.CustomerStories .CustomerStories-Right-Text h3 {
  font-size: 16px;
  font-weight: 700;
}

.CaseStudy {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.CaseStudy .CaseStudyTitle h1 {
  font-size: 40px;
  font-weight: 700;
}

.CaseStudy .CaseStudyTitle h2 {
  font-size: 32px;
  font-weight: 700;
}

.CaseStudy .CaseStudyTitle p {
  font-size: 16px;
  font-weight: 400;
}

.CaseStudy .CaseStudyTitle .CaseStudyTopTitle {
  display: flex;
}

.CaseStudy .CaseStudyTitle .CaseStudyTopTitle p span {
  font-size: 14px;
  font-weight: 400;
}

.CaseStudy .CaseStudyTitle .CaseStudyCustomerIndustry h5 {
  color: #86909c;
  font-size: 16px;
  font-weight: 400;
}

.CaseStudy .CaseStudyTitle .CaseStudyCustomerIndustry button {
  margin-bottom: 10px;
}

.CaseStudy .CaseStudyTitle .CaseStudyCustomerIndustry button + button {
  margin-left: 5px;
}

@media (max-width: 600px) {
  .CaseStudyTopTitle {
    flex-direction: column;
  }
  .CaseStudyTopTitle .CaseStudyIcon {
    display: none;
  }
}

.FeedBack {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.FeedBack .FeedBackTitle {
  text-align: center;
}

.FeedBack .FeedBackTitle h1 {
  font-size: 48px;
  font-weight: 700;
}

.FeedBack .FeedBackTitle p {
  font-size: 18px;
  font-weight: 400;
}

.FeedBack .FeedBack-Box {
  text-align: center;
  border-radius: '28px';
}

.FeedBack .FeedBack-Box .FeedBack-Info {
  display: flex;
  text-align: center;
}

.FeedBack .FeedBack-Box .FeedBack-Info img {
  border-radius: 50%;
}

.FeedBack .FeedBack-Box .FeedBack-Info .FeedBack-Text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
}

.FeedBack .FeedBack-Box .FeedBack-Info .FeedBack-Text h3 {
  font-size: 18px;
  font-weight: 700;
}
