@charset "UTF-8";
.FrontierExploration {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.FrontierExploration .FrontierExplorationTitle {
  text-align: center;
}

.FrontierExploration .FrontierExplorationTitle h1 {
  font-size: 48px;
  font-weight: 700;
}

.FrontierExploration .FrontierExplorationTitle p {
  font-size: 18px;
  font-weight: 400;
}

.FrontierExploration .FrontierExplorationImg img {
  border-radius: 28px;
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

@media (min-width: 876px) {
  .FrontierExplorationTitle {
    width: 840px;
  }
}

.IndustryLatest {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.IndustryLatest .IndustryLatestTitle {
  text-align: center;
}

.IndustryLatest .IndustryLatestTitle h1 {
  font-size: 48px;
  font-weight: 700;
}

.IndustryLatest .IndustryLatestTitle p {
  font-size: 18px;
  font-weight: 400;
}

.IndustryLatest .IndustryLatest-Card img {
  max-width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-radius: 32px;
  background: red;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Title {
  padding: 20px 0;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Title span {
  font-size: 16px;
  font-weight: 500;
  color: blue;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Title h5 {
  font-size: 24px;
  font-weight: 700;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Title p {
  overflow: hidden;
  /* 隐藏超出容器的内容*/
  text-overflow: ellipsis;
  /* 在文本溢出时显示省略号*/
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Info {
  display: flex;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Info img {
  border-radius: 50%;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Info .IndustryLatest-Text {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Info .IndustryLatest-Text h5 {
  font-size: 16px;
  font-weight: 500;
}

.IndustryLatest .IndustryLatest-Card .IndustryLatest-Info .IndustryLatest-Text p {
  font-size: 14px;
  font-weight: 400;
  color: #86909c;
}
