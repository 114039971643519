.Footer-Bottom .Footer-Bottom-TopTitle {
  padding: 60px 60px 60px 60px;
}

.Footer-Bottom .Footer-Bottom-Title {
  padding-top: 20px;
  display: flex;
}

.Footer-Bottom .Footer-Bottom-Title p {
  cursor: pointer;
  font-size: 16px;
}

.Footer-Bottom .Footer-Bottom-Title span {
  font-weight: 700;
}

.Footer-Bottom .Footer-Bottom-Title a {
  color: #252525;
}

.Footer-Bottom .Footer-Bottom-Title p + p {
  margin-left: 15px;
}

@media (min-width: 768px) {
  .PageFooter-Col {
    padding-left: 15px;
  }
}
