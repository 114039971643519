@charset "UTF-8";
.ContactUs {
  background-color: #f9fafb;
  padding: 60px 60px 60px 60px;
  background-image: url(https://img.js.design/assets/img/60f8dd3f61957d72f58659f8.png);
  position: relative;
  height: 1015px;
  background-size: 100% 70%;
  /* 宽度100%,高度70% */
  background-repeat: no-repeat;
  background-position: bottom;
  /* 使背景图位于盒子底部 */
}

.ContactUs .OurAdvantages-Text h1 {
  font-size: 60px;
  font-weight: 600;
}

.ContactUs .OurAdvantages-Text p {
  font-size: 21px;
  font-weight: 400;
}

.ContactUs .ContactUs-Card {
  border-radius: 28px;
  max-width: 500px;
  opacity: 1;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
}

.ContactUs .ContactUs-Icon {
  font-size: 36px;
  color: red;
  position: absolute;
  top: 55%;
  left: 30%;
  fill: red;
}

@media (max-width: 768px) {
  .ContactUs {
    background-size: 100% 65%;
    /* 宽度100%,高度65% */
  }
  .OurAdvantages-Text {
    margin-bottom: 30px;
  }
}

@media (max-width: 995px) {
  .ContactUs-Icon {
    display: none;
  }
}
