.AboutUs {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.AboutUs .AboutUs-Text h1 {
  font-size: 56px;
  font-weight: 700;
}

.AboutUs .AboutUs-Text p {
  font-size: 18px;
  font-weight: 400;
}

.AboutUs .AboutUs-Box {
  display: flex;
  justify-content: center;
  height: 200px;
  position: relative;
}

.AboutUs .AboutUs-Box img {
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 32px !important;
}

.AboutUs .AboutUs-Box .AboutUS-Img {
  max-width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(30deg) rotateX(-45deg);
}

.AboutUs .AboutUs-Box .AboutUS-Img:nth-child(1) {
  z-index: 666;
  transform: translate(-10px, 10px) rotateX(-45deg) rotate(30deg);
}

.AboutUs .AboutUs-Box .AboutUS-Img:nth-child(2) {
  z-index: 555;
  transform: translate(-20px, 50px) rotateX(-45deg) rotate(30deg);
  margin-left: 30px;
}

.AboutUs .AboutUs-Box .AboutUS-Img:nth-child(3) {
  z-index: 444;
  transform: translate(-30px, 90px) rotateX(-45deg) rotate(30deg);
  margin-left: 50px;
}

@media (min-width: 768px) {
  .AboutUs-Box {
    margin-top: 50px;
  }
}

.OurTeam {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.OurTeam .OurTeam-Text h1 {
  font-size: 48px;
  font-weight: 700;
}

.OurTeam .OurTeam-Text p {
  font-size: 18px;
  font-weight: 400;
}

.OurTeam .OurTeam-Card {
  width: 290px;
  border-radius: 28px;
}

.OurTeam .OurTeam-Card .OurTeam-Img {
  width: 80px;
  height: 80px;
}

.OurTeam .OurTeam-Card .OurTeam-Img img {
  max-width: 100%;
  border-radius: 50%;
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.OurTeam .OurTeam-Card h3 {
  font-size: 20px;
  font-weight: 700;
}

.OurTeam .OurTeam-Card p {
  font-size: 18px;
  font-weight: 500;
}

.OurTeam .OurTeam-Card span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.OurTeam .OurTeam-Bottom {
  text-align: center;
  margin-top: 40px;
}

.OurTeam .OurTeam-Bottom h4 {
  font-size: 32px;
  font-weight: 700;
}

.OurTeam .OurTeam-Bottom p {
  font-size: 18px;
  font-weight: 400;
}

.OurTeam-Row > * {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.OurAdvantages {
  padding: 60px 60px 60px 60px;
  background-color: #f9fafb;
}

.OurAdvantages .OurAdvantages-Text h1 {
  font-size: 48px;
  font-weight: 700;
}

.OurAdvantages .OurAdvantages-Text p {
  font-size: 18px;
  font-weight: 400;
}

.OurAdvantages .OurAdvantages-Row .OurAdvantages-Img {
  margin-top: 40px;
  width: 700px;
  height: 440px;
}

.OurAdvantages .OurAdvantages-Row .OurAdvantages-Img img {
  max-width: 100%;
  border-radius: 28px;
  max-width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.OurAdvantages .OurAdvantages-Row .OurAdvantages-Right-Text {
  margin-left: 60px;
  margin-top: 40px;
  width: 478px;
  height: 80px;
  position: relative;
}

.OurAdvantages .OurAdvantages-Row .OurAdvantages-Right-Text .OurAdvantages-Right-Icon {
  width: 30px;
  height: 25px;
  position: absolute;
  margin-top: 6px;
  margin-left: -40px;
}

.OurAdvantages .OurAdvantages-Row .OurAdvantages-Right-Text h3 {
  font-size: 20px;
  font-weight: 600;
  color: blue;
}

.OurAdvantages .OurAdvantages-Row .OurAdvantages-Right-Text p {
  font-size: 14px;
  font-weight: 400;
}
